.dropdown {
  width: 100% !important;
}

.dropdown-trigger {
  width: 100% !important;
}

.tag-list {
  display: inline-block !important;
  width: calc(100% - 30px) !important;
}
