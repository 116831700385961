@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  @apply font-montserrat;
}

.react-tabs__tab-list {
  border-bottom: none !important;
  margin: 0px !important;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.profile_tab {
  width: 100% !important;
  padding: 1rem !important;
  background-color: #f5f5f5 !important;
  color: #000000 !important;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}

.border_header {
  margin-bottom: -1.2%;
  background: white;
  /* z-index: 100001; */
  position: relative;
  width: fit-content;
}

.react-tabs__tab--selected {
  background: transparent !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 4px solid theme("colors.theme-blue") !important;
  background-color: white !important;
}

.react-tabs__tab:focus:after {
  background: none !important;
}

.react-tabs__tab:focus {
  box-shadow: none !important;
  border: none !important;
  border-bottom: 2px solid theme("colors.theme-blue") !important;
  outline: none;
}

.left-side {
  left: 15% !important;
}

.negative-margin {
  margin-left: -50%;
}

.logincontentbackground {
  background-color: theme("colors.theme-darkslategrey");
}

.loginformbackground {
  background: url("../public/images/form-bg.jpg"), rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.logintextbackground {
  background: url("../public/icons/login-sk.svg");
  background-size: contain;
}

.earningstab .react-tabs__tab {
  /* padding: 0; */
  padding: 6px 10px !important;
}

.earningstab .react-tabs__tab-list {
  overflow: hidden;
}

.earningstab #react-tabs-0 {
  border-right: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.earningstab #react-tabs-4 {
  border-right: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.earningstab #react-tabs-2 {
  border-right: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}

.earningstab .react-tabs__tab-panel {
  margin-top: 4% !important;
}

.earningstab .react-tabs__tab--selected {
  border: none !important;
  background: rgb(194, 192, 192) !important;
}

/* .loginformbackground:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.5;
} */
.graphsTab {
  display: inline-block;
  position: relative;
  list-style: none;
  cursor: pointer;
  padding: 6px 10px !important;
  bottom: -1px;
}

.graphsTab1 {
  border: 1px solid #4c4c4c;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.graphsTab2 {
  border-right: 1px solid #4c4c4c;
  border-bottom: 1px solid #4c4c4c;
  border-top: 1px solid #4c4c4c;
}

.graphsTab3 {
  border-right: 1px solid #4c4c4c;
  border-bottom: 1px solid #4c4c4c;
  border-top: 1px solid #4c4c4c;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.graphsTabActive {
  background: rgb(194, 192, 192) !important;
}

.earningconvtab .react-tabs__tab {
  border-top: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
}

.earningconvtab ul li:first-child {
  border-left: 1px solid #000000 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.earningconvtab ul li:last-child {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.earningconvtab .react-tabs__tab--selected {
  border-bottom: 1px solid #000000 !important;
  background: rgb(194, 192, 192) !important;
}

.tag-remove {
  padding: 5px !important;
  font-size: 95% !important;
}

.tag-item .search {
  border-bottom: 0px !important;
}
